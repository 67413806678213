import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { type Database } from '@wision/supabase/types';

type Options = {
  onSuccess?: () => void
  onError?: () => void
}

export const useSetLanguage = (options?: Options) => {
  const { $supabase } = useNuxtApp();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (language: string) => {
      const session = await $supabase.auth.getSession();

      if (session.error) throw new Error(session.error.message);

      const { error } = await $supabase
        .from('users')
        .update({ language: language as Database['public']['Enums']['language'] })
        .eq('id', session.data.session?.user.id ?? '')
        .single();

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userProfile']
      });
      if (options?.onSuccess) options.onSuccess();
    },
    onError: () => {
      if (options?.onError) options.onError();
    }
  });
};
