import useApiClient, { useRequestInit } from './apiClient';
import { type AuthTokenResponsePassword } from '@supabase/supabase-js';
export const useAuthApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const login = async (email: string, password: string) =>
    await apiClient<AuthTokenResponsePassword>(
      `${baseUrl}/api/v2/auth/login`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
    );

  return {
    login
  };
};
