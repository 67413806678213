import { useMutation, useQuery, useQueryClient  } from '@tanstack/vue-query';
import { withUseQuery } from '@wision/supabase/helpers';
import { jwtDecode } from 'jwt-decode';
import type { Database } from '@wision/supabase/types';

export type UserGroup = Database['public']['Enums']['user_group'];

type Options = {
  onSuccess?: () => void
  onError?: () => void
}

type JWT = {
  session_id: string
}

const decodeJwt = (token: string): JWT => {
  const decoded = jwtDecode(token) as { session_id: string };
  return decoded;
};

export const sessionQueryKyes = {
  sessionId: 'sessionId',
  session: 'session',
  sessions: 'sessions',
  currentOrganization: 'currentOrganization',
  currentOrgAndRole: 'currentOrgAndRole'
};

export const useGetSessionId = () => {
  const { $supabase } = useNuxtApp();

  return useQuery({
    queryKey: [sessionQueryKyes.sessionId],
    queryFn: async () => {
      const session = await $supabase.auth.getSession();

      if (session.error) throw new Error(session.error?.message);

      const accessToken = session.data.session?.access_token;
      if (!accessToken)
        throw new Error('No access token found');

      const sessionId = decodeJwt(accessToken).session_id;

      return sessionId;
    }
  });
};

export const useGetCurrentOrganization = () => {
  const { $supabase } = useNuxtApp();

  return useQuery({
    queryKey: [sessionQueryKyes.currentOrganization],
    queryFn: withUseQuery(async () => {

      return await $supabase.rpc('get_current_organization');
    })
  });
};

export const useGetCurrentOrgAndRole = () => {
  const { $supabase } = useNuxtApp();

  return useQuery({
    queryKey: [sessionQueryKyes.currentOrgAndRole],
    queryFn: async () => {
      const { data, error } = await $supabase.rpc('get_current_org_and_role');

      if (error) throw error;

      return data as { org_id: string, role: UserGroup };
    },
  });
};

export const useGetSession = () => {
  const { $supabase } = useNuxtApp();

  return useQuery({
    queryKey: [sessionQueryKyes.session],
    queryFn: withUseQuery(
      async () => {
        const session = await $supabase.auth.getSession();

        return await $supabase.from('sessions').select('*').eq('id', session.data.session?.user.id ?? '').single();
      }
    )
  });
};

export const useSetCurrentOrganization = (options?: Options) => {
  const queryClient = useQueryClient();
  const apiClient = useApi();

  return useMutation({
    mutationFn: async (organizationId: string) => {
      const [data, error] = await apiClient.user.updateCurrentOrganization(organizationId);

      if (error) throw new Error('Failed to update current organization');

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [sessionQueryKyes.session]
      });
      if (options?.onSuccess) options.onSuccess();
    },
    onError: () => {
      if (options?.onError) options.onError();
    }
  });
};

export const useGetSessions = () => {
  const { $supabase } = useNuxtApp();

  return useQuery({
    queryKey: [sessionQueryKyes.sessions],
    queryFn: withUseQuery(async () => await $supabase.rpc('get_user_sessions'))
  });
};
