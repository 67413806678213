import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

const LOGIN_PATH = '/login';
const NO_ACCESS_PATH = '/no-access';

export default defineNuxtRouteMiddleware(async () => {
  const { $supabase } = useNuxtApp();

  const [user, level] = await Promise.all([
    $supabase.auth.getUser(),
    $supabase.auth.mfa.getAuthenticatorAssuranceLevel()
  ]);

  if (
    user.error ||
    level.error ||
    !level.data?.currentLevel ||
    level.data?.currentLevel != level.data?.nextLevel
  )
    return navigateTo(LOGIN_PATH);

  const userAccess = await $supabase.rpc('get_user_access', {
    user_id: user.data.user.id
  });

  if (userAccess.data?.length === 0) {
    return navigateTo(NO_ACCESS_PATH);
  }
});

