<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { WisionLogo, CustomButton } from '@wision/ui';

const { t } = useI18n();
const code = ref('');

const emits = defineEmits<{
  (e: 'verify', code: string): void;
}>();

const handleVerify = () => {
  emits('verify', code.value);
};
</script>

<template>
  <div class="w-full">
    <WisionLogo :width="64" />
    <p class="text-center mt-5 text-gray-400">
      {{ t('mfa.entercode') }}
    </p>
    <div class="my-4 space-y-2">
      <fieldset>
        <div class="relative text-gray-300">
          <input
            v-model="code"
            :placeholder="t('mfa.code')"
            type="text"
            required
            class="border border-gray-500 w-full h-10 text-base placeholder-gray-540 bg-transparent rounded-lg focus:shadow-outline text-center"
          >
        </div>
      </fieldset>
    </div>
    <CustomButton
      class="flex items-center justify-around w-full py-2 rounded disabled:opacity-70"
      :types="['auto-width', 'border']"
      @click="handleVerify"
    >
      <span>{{ t('common.continue') }}</span>
    </CustomButton>
  </div>
</template>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
</style>
