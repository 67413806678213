<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { WisionLogo, CustomButton } from '@wision/ui';

const password = ref();
const email = ref();
const { t } = useI18n();

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits<{
  (e: 'login', email: string, password: string): void;
}>();

const handleLogin = () => {
  emits('login', email.value, password.value);
};
</script>

<template>
  <div class="w-full">
    <WisionLogo :width="64" />
    <p class="text-center mt-5 text-gray-400">
      {{ t('login.loginwithemail') }}
    </p>
    <div class="my-4 space-y-2">
      <fieldset>
        <div class="relative text-gray-300">
          <input
            ref="username"
            v-model="email"
            :placeholder="t('login.email')"
            type="text"
            required
            class="border border-gray-500 w-full h-10 text-base placeholder-gray-540 bg-transparent rounded-lg focus:shadow-outline text-center"
          />
        </div>
      </fieldset>
      <fieldset>
        <div class="relative text-gray-300">
          <input
            v-model="password"
            :placeholder="t('login.password')"
            type="password"
            required
            class="border border-gray-500 w-full h-10 text-base placeholder-gray-400 bg-transparent rounded-lg focus:shadow-outline text-center"
            @keyup.enter="handleLogin"
          />
        </div>
      </fieldset>
    </div>
    <CustomButton
      class="flex items-center justify-around w-full py-2 rounded disabled:opacity-70"
      :types="['auto-width', 'border']"
      @click="handleLogin"
    >
      <svg
        v-if="props.isLoading"
        class="w-6 h-6 text-white animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>

      <span>{{ t('login.signin') }}</span>

      <div v-if="props.isLoading" class="w-6 h-6" />
    </CustomButton>

    <NuxtLink to="/restore-password">
      <p class="text-center mt-2 underline cursor-pointer text-gray-300">
        Forgot password?
      </p>
    </NuxtLink>
  </div>
</template>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
</style>
