import type { AuthTokenResponse, Factor } from '@supabase/supabase-js';
import { ref } from 'vue';
import { useAuthApi } from '@wision/api';

type Result<T, E = Error> =
  | { ok: true, value: T }
  | { ok: false, error: E }

const fail = <T>(err: unknown): Result<T> =>
  err instanceof Error
    ? { ok: false, error: err }
    : { ok: false, error: new Error(String(err)) };

const ok = <T>(value: T): Result<T> => ({ ok: true, value });

const tryCatch = async <T>(
  f: () => Promise<Result<T>>
): Promise<Result<T>> => {
  try {
    return await f();
  } catch (err) {
    return fail(err);
  }
};

type Handlers = {
  onEnroll?: () => void,
  onLogin?: () => void,
  onVerify?: () => void,
}

export type TotpFactor = { factorId: string, qr: string };
type LoginResponse = [Extract<AuthTokenResponse, { error: null }>['data'], boolean];

export const useSupabaseAuth = (handlers: Handlers = {}) => {
  const config = useRuntimeConfig();
  const auth = useAuthApi(config.public.apiHost, import.meta.server);

  const { $supabase } = useNuxtApp();

  const factors = ref<Array<Factor>>([]);
  const currentFactor = ref<string>();

  const selectFactor = (
    factorId: string
  ): void => {
    if (factors.value.find(factor => factor.id == factorId))
      currentFactor.value = factorId;
  };

  const login = async (
    id: string,
    password: string
  ): Promise<Result<LoginResponse>> =>
    tryCatch(async () => {
      const [authResponse] = (await auth.login(id, password));
      if (authResponse.error) {
        return fail(String(authResponse.error));
      }

      const result = await fetchFactors();

      if (!result.ok)
        return fail(result.error);
      else
        factors.value = result.value;

      if (factors.value.length) {
        currentFactor.value = factors.value[0].id;
      }

      const level = await $supabase.auth.mfa.getAuthenticatorAssuranceLevel();

      const mfaRequired = level.data?.currentLevel == 'aal1' && level.data.nextLevel == 'aal2';

      // Run login handler if no additional authentication is required
      if (!mfaRequired && handlers.onLogin) handlers.onLogin();

      return ok([authResponse.data, mfaRequired]);
    });

  const fetchFactors = async (
  ): Promise<Result<Array<Factor>>> =>
    tryCatch(async () => {
      const factorsResponse = await $supabase.auth.mfa.listFactors();

      if (factorsResponse.error)
        return fail(factorsResponse.error);

      factors.value = factorsResponse.data.all;

      return ok(factorsResponse.data.all);
    });

  const enroll = async (
  ): Promise<Result<TotpFactor>> =>
    tryCatch(async () => {
      const enrollResponse = await $supabase.auth.mfa.enroll({
        factorType: 'totp'
      });

      if (enrollResponse.error)
        return fail(enrollResponse.error);

      if (handlers.onEnroll) handlers.onEnroll();

      currentFactor.value = enrollResponse.data.id;

      const result = await fetchFactors();

      if (!result.ok)
        return fail(result.error);

      return ok({
        factorId: enrollResponse.data.id,
        qr: enrollResponse.data.totp.qr_code
      });
    });

  const unenroll = async (
    factorId: string
  ): Promise<Result<void>> =>
    tryCatch(async () => {
      const unenrollResponse = await $supabase.auth.mfa.unenroll({
        factorId
      });

      if (unenrollResponse.error)
        return fail(unenrollResponse.error);

      const result = await fetchFactors();

      if (!result.ok)
        return fail(result.error);

      if (currentFactor.value == factorId) currentFactor.value = undefined;

      return ok(undefined);
    });

  const verifyLoginFactor = async (
    code: string
  ): Promise<Result<void>> => {
    const result = await enableFactor(code);

    if (!result.ok) return fail(result.error);

    if (handlers.onLogin)
      handlers.onLogin();

    return ok(undefined);
  };

  const enableFactor = async (
    code: string
  ): Promise<Result<void>> =>
    tryCatch(async () => {
      if (!currentFactor.value)
        return fail('No MFA Factor available');

      const challengeResponse = await $supabase.auth.mfa.challenge({ factorId: currentFactor.value });

      if (challengeResponse.error)
        return fail(challengeResponse.error);

      const challengeId = challengeResponse.data.id;

      const verificationResponse = await $supabase.auth.mfa.verify({
        factorId: currentFactor.value,
        challengeId,
        code: code.trim(),
      });

      if (verificationResponse.error)
        return fail(verificationResponse.error);

      if (handlers.onVerify) handlers.onVerify();

      const result = await fetchFactors();

      if (!result.ok)
        return fail(result.error);

      return ok(undefined);
    });

  return {
    factors,
    selectFactor,
    fetchFactors,
    currentFactor,
    login,
    enroll,
    unenroll,
    enableFactor,
    verifyLoginFactor,
  };
};
